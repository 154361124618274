<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.productModel"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                        prop="name"
                        :label="$t('message.name')"
                      >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                        ></el-input>
                      </el-form-item>  

                      <el-form-item
                        prop="code"
                        :label="$t('message.code')"
                      >
                        <el-input
                            :placeholder="$t('message.code')"
                            v-model="form.code"
                            size="medium"
                        ></el-input>
                      </el-form-item>  

                      <el-form-item
                        prop="status_id"
                        :label="$t('message.status')"
                      >
                        <select-status
                          :size="'medium'"
                          :placeholder="columns.status.title"
                          :id="form.status_id"
                          v-model="form.status_id"
                        >
                        </select-status>
                      </el-form-item>  

                      <el-form-item
                        :label="$t('message.productSizes')"
                        class="label_mini"
                        prop="sizes"
                      >
                        <select-sizes
                          ref="sizes"
                          v-model="form.sizes"
                          :productSize_ids="form.sizes"
                          :size="'medium'"
                          :placeholder="$t('message.productSizes')"
                        >
                        </select-sizes>
                      </el-form-item>

                      <el-form-item
                        :label="$t('message.colors')"
                        class="label_mini"
                        prop="colors"
                      >
                        <select-colors
                          ref="colors"
                          v-model="form.colors"
                          :color_ids="form.colors"
                          :size="'medium'"
                          :placeholder="$t('message.colors')"
                        >
                        </select-colors>
                      </el-form-item>

                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectStatus from "@/components/inventory/select-product-model-status";
import selectSizes from "@/components/multiSelects/multi-select-product-sizes";
import selectColors from "@/components/multiSelects/multi-select-colors";

export default {
  
  mixins: [form, drawer],
  components: {
    selectStatus,
    selectSizes,
    selectColors,
  },
  data() {
    return {

    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "productModels/rules",
          model: "productModels/model",
          columns: "productModels/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "productModels/store",
      }),
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
      afterLeave(){
        this.$refs['sizes'].selected = [];
      }
  },
};
</script>
